<template>
  <div class="navbar-interactive-container" :class="{blackBackground:scrollDown}" >
    <header data-thq="thq-navbar" class="navbar-interactive-navbar-interactive">
      <router-link to="/" class="navbar-interactive-navlink">
        <img
          alt="logo"
          src="/logo%201-1500h.png"
          class="navbar-interactive-image"
        />
      </router-link>
      <div data-thq="thq-navbar-nav" class="navbar-interactive-desktop-menu">
        <nav class="navbar-interactive-links">
          <router-link to="/" class="navbar-interactive-navlink01 NavigationBar">
            首页
          </router-link>
          <router-link
            to="/huahua"
            class="navbar-interactive-navlink02 NavigationBar"
          >
            HUAHUA话画
          </router-link>
          <router-link
            to="/3dchuangyi"
            class="navbar-interactive-navlink03 NavigationBar"
          >
            SOLART 3D创易
          </router-link>
          <router-link
            to="/huahuakids"
            class="navbar-interactive-navlink04 NavigationBar"
          >
            HUAHUA Kids话画少年
          </router-link>
          <router-link
            to="/news"
            class="navbar-interactive-navlink05 NavigationBar"
          >
            新闻
          </router-link>
          <router-link
            to="/about"
            class="navbar-interactive-navlink05 NavigationBar"
          >
            关于我们
          </router-link>

        </nav>
      </div>
      <div data-thq="thq-burger-menu" class="navbar-interactive-burger-menu" v-on:click="handleClick">
        <img alt="image" src="/group%202.svg" class="navbar-interactive-image1" />
      </div>
      <div data-thq="thq-mobile-menu" class="navbar-interactive-mobile-menu" :class="{mobileMenuOpen:isMenuOpen}">
        <div class="navbar-interactive-nav">
          <div class="navbar-interactive-top">
            <img
              alt="image"
              src="/logo%201-1500h.png"
              class="navbar-interactive-logo"
            />
            <div data-thq="thq-close-menu" class="navbar-interactive-close-menu" v-on:click="handleClick">
              <img
                alt="image"
                src="/vector.svg"
                class="navbar-interactive-image2"
              />
            </div>
          </div>
          <nav class="navbar-interactive-links1">
            <router-link
              to="/"
              class="navbar-interactive-navlink-mobile NavigationBar"
            >
              首页
            </router-link>
            <router-link
              to="/huahua"
              class="navbar-interactive-navlink-mobile NavigationBar"
            >
              HUAHUA话画
            </router-link>
            <router-link
              to="/3dchuangyi"
              class="navbar-interactive-navlink-mobile NavigationBar"
            >
              SOLART 3D创易
            </router-link>
            <router-link
              to="/huahuakids"
              class="navbar-interactive-navlink-mobile NavigationBar"
            >
              HUAHUA kids话画少年
            </router-link>
            <router-link
              to="/news"
              class="navbar-interactive-navlink-mobile NavigationBar"
            >
              新闻
            </router-link>
            <router-link
              to="/about"
              class="navbar-interactive-navlink-mobile NavigationBar"
            >
              关于我们
            </router-link>
  
          </nav>
        </div>
        <div>
          <svg
            viewBox="0 0 950.8571428571428 1024"
            class="navbar-interactive-icon"
          >
            <path
              d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
            ></path></svg
          ><svg
            viewBox="0 0 877.7142857142857 1024"
            class="navbar-interactive-icon2"
          >
            <path
              d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
            ></path></svg
          ><svg
            viewBox="0 0 602.2582857142856 1024"
            class="navbar-interactive-icon4"
          >
            <path
              d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"
            ></path>
          </svg>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'NavbarInteractive',
  props: {
    rootClassName: String,
    defaultBlack: Boolean,
  },
  data() {
    return {
      isMenuOpen: false,
      scrollDown: false
    }
  },
  created() {
    //when scroll down, hide the navbar
    window.addEventListener('scroll', this.handleScroll);
    if(this.defaultBlack){
      this.scrollDown = true;
    }
  },methods: {
    handleScroll() {
      if(this.defaultBlack){
        return;
      }
      if (window.scrollY > 0) {
        this.scrollDown = true;
      }else{
        this.scrollDown = false;
      }
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    handleClick(){
      this.isMenuOpen = !this.isMenuOpen;
      //find the element "#home-container"
      const homeContainer = document.getElementById('page-container');
      //add a class "preventScroll" to it
      homeContainer.classList.toggle('preventScroll');
    }
  },
}
</script>

<style scoped>
  .blackBackground{
    background: #000000;
  }
.navbar-interactive-navlink-mobile{
  font-size: 36px;
  padding-bottom: 10px;
  margin-bottom:32px ;
  margin-left:10px ;
  font-weight: 400;
  border-bottom: 2px solid #fff;
}

@media  (max-width: 425px) {
  .navbar-interactive-navlink-mobile{
    font-size: 24px;
  }
}
@media (max-height: 564px) {
  .navbar-interactive-navlink-mobile{
    margin-bottom:22px ;
  }
}
@media (max-height: 509px) {
  .navbar-interactive-navlink-mobile{
    margin-bottom:15px ;
  }
}


.navbar-interactive-container {
  height: 76px;
  transition: .15s;
  z-index: 99;
  width: 100%;
  display: flex;
  position: fixed;
}
.navbar-interactive-navbar-interactive {
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.navbar-interactive-navlink {
  display: contents;
}
.navbar-interactive-image {
  height: 3rem;
  text-decoration: none;
}
@media (max-width: 355px) {
  .navbar-interactive-image{
    height: 2rem !important;
  }
}
.navbar-interactive-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.navbar-interactive-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navbar-interactive-navlink01 {
  color: rgba(255, 255, 255, 0.65);
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.navbar-interactive-navlink01:hover {
  color: var(--dl-color-gray-white);
}
.navbar-interactive-navlink01:active {
  color: var(--dl-color-gray-white);
}
.navbar-interactive-navlink02 {
  color: rgba(255, 255, 255, 0.65);
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.navbar-interactive-navlink02:hover {
  color: var(--dl-color-gray-white);
}
.navbar-interactive-navlink02:active {
  color: var(--dl-color-gray-white);
}
.navbar-interactive-navlink03 {
  color: rgba(255, 255, 255, 0.65);
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.navbar-interactive-navlink03:hover {
  color: var(--dl-color-gray-white);
}
.navbar-interactive-navlink03:active {
  color: var(--dl-color-gray-white);
}
.navbar-interactive-navlink04 {
  color: rgba(255, 255, 255, 0.65);
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.navbar-interactive-navlink04:hover {
  color: var(--dl-color-gray-white);
}
.navbar-interactive-navlink04:active {
  color: var(--dl-color-gray-white);
}
.navbar-interactive-navlink05 {
  color: rgba(255, 255, 255, 0.65);
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.navbar-interactive-navlink05:hover {
  color: var(--dl-color-gray-white);
}
.navbar-interactive-navlink05:active {
  color: var(--dl-color-gray-white);
}
.navbar-interactive-burger-menu {
  display: none;
}
.navbar-interactive-image1 {
  width: 20px;
  object-fit: cover;
}
@media (max-width: 301px) {
  .navbar-interactive-logo {
    height: 2rem !important;
}

}
.navbar-interactive-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 999;
  position: absolute;
  transform: translateX(-200%);
  transition: 0.5s;
  transition-property:transform;
  flex-direction: column;
  justify-content: space-between;
  background-color: #000000;
}

.mobileMenuOpen{
  transform: translateX(0);

}
.navbar-interactive-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-interactive-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.navbar-interactive-logo {
  height: 4rem;
}
.navbar-interactive-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-interactive-image2 {
  width: 40px;
  object-fit: cover;
}
.navbar-interactive-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-interactive-navlink06 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-interactive-navlink07 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-interactive-navlink08 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-interactive-navlink09 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-interactive-navlink10 {
  color: var(--dl-color-gray-white);
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-interactive-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.navbar-interactive-icon2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.navbar-interactive-icon4 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}






@media(max-width: 1010px) {
  .navbar-interactive-desktop-menu {
    display: none;
  }
  .navbar-interactive-links {
    justify-content: flex-end;
  }
  .navbar-interactive-navlink01 {
    color: rgb(255, 255, 255);
  }
  .navbar-interactive-navlink02 {
    color: rgb(255, 255, 255);
  }
  .navbar-interactive-navlink03 {
    color: rgb(255, 255, 255);
  }
  .navbar-interactive-navlink04 {
    color: rgb(255, 255, 255);
  }
  .navbar-interactive-navlink05 {
    color: rgb(255, 255, 255);
  }
  .navbar-interactive-burger-menu {
    display: flex;
  }
}
@media(max-width: 767px) {
  .navbar-interactive-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .navbar-interactive-image {
    height: 4rem;
  }
  .navbar-interactive-burger-menu {
    align-items: center;
    justify-content: center;
  }
  .navbar-interactive-mobile-menu {
    background-color: #000000;
  }
  .navbar-interactive-navlink06 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .navbar-interactive-navlink07 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .navbar-interactive-navlink08 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navbar-interactive-navlink09 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navbar-interactive-navlink10 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .navbar-interactive-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .navbar-interactive-mobile-menu {
    padding: 16px;
  }
}
</style>
