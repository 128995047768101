<template>
    <carousel :data="data" :autoplay="true" :interval="3000"></carousel>
</template>

<script>
export default {
  name: 'ProductCarousel',
  props: {
    image_src: {
      type: Array,
      default(){return [
        '/exampleSlide1.png',
        '/exampleSlide2.png',
        '/exampleSlide3.png',
      ]},
    },
  },
  computed: {
    data() {
      return this.image_src.map((img) => {
        return `<div class="carouselSlide"><img class="carouselImg" src="${img}"></div>`
      })
    },
  },
}
</script>

<style>
    .carouselImg{
        width: 40rem;
        height: auto;
        object-fit: cover;
    }
</style>