<template>
    <div class="underlineBlock"></div>
</template>

<script>
export default {
    name: 'underlineBlock',

}
</script>

<style scoped>
.underlineBlock{
  width: 100%;
  height: 3px;
  background-image: linear-gradient(90deg, #016DED 6%, #00B2F8 50%);
  border-radius: 3px;

}
</style>