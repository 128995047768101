<template>
  <div class="news-card-news-card" @click="redirect">
    <img :src="Cover_src" :alt="Cover_alt" class="news-card-cover" />
    <div class="news-card-contentcontainer">
      <div class="news-card-subtitle">
        <span class="news-card-text">{{ subTitle }}</span>
        <underline-block></underline-block>
      </div>
      <span class="news-card-text1">{{ mainTitle }}</span>
      <span class="news-card-text2">{{ summary }}</span>
      <span class="news-card-text3">{{ time }}</span>
    </div>
  </div>
</template>

<script>
import he from 'he'

export default {
  name: 'NewsCard',
  components: {
    UnderlineBlock: () => import('./underlineBlock.vue'),
  },
  created() {
    //console.log(this.newsData)
    const newsContent = this.newsData.content;
    const parsedJson = this.parseHTML(newsContent);
    this.summary = this.newsData.excerpt;
    //find the first image
    for (let i = 0; i < parsedJson.data.length; i++) {
      if (parsedJson.data[i].type == 'image') {
        this.Cover_src = parsedJson.data[i].src;
        this.Cover_alt = parsedJson.data[i].alt;
        break;
      }
    }
    //find the time
    this.time = this.newsData.time;

    //console.log(parsedJson)
    
  },
  data() {
    return {
      summary: '',
      time:'',
      Cover_src: '/placeholder 1 (1)-1500w.png',
      Cover_alt: '',
    }
  }
  ,
  methods:{
    redirect:function(){
      this.$router.push({ name: 'NewsDetails', params: { id: this.newsID } })
    },
    parseHTML:function parseHTMLToJSON(htmlString) {
            // Decode the HTML entities
            const decodedHTML = he.decode(htmlString);

            const div = document.createElement('div');
            div.innerHTML = decodedHTML.trim();

            function processNode(node) {
              function extractImgFromParagraph(pNode) {
                const imgNode = pNode.querySelector('img');
                if (imgNode) {
                  return {
                    type: 'image',
                    src: 'http://solart.pro/upload/' + imgNode.getAttribute('src'),
                    title: imgNode.getAttribute('title'),
                    alt: imgNode.getAttribute('alt')
                  };
                }
                return null;
              }

              switch (node.nodeName.toLowerCase()) {
                case 'p':
                  const imgNodeFromParagraph = extractImgFromParagraph(node);
                  if (imgNodeFromParagraph) {
                    return imgNodeFromParagraph;
                  }
                  return { type: 'paragraph', content: node.innerHTML };
                case 'h1':
                  return {
                    type: 'header',
                    level: parseInt(node.nodeName.charAt(1)),
                    attributes: {
                      label: node.getAttribute('label'),
                      style: node.getAttribute('style')
                    },
                    content: node.innerHTML
                  };
                default:
                  return null;
              }
            }

            const data = Array.from(div.children).map(processNode).filter(Boolean);

            return { data };
          }
  },
  props: {
    SVG1_src: {
      type: String,
      default: '/external/svg14536-4zx9.svg',
    },
    SVG1_alt: {
      type: String,
      default: 'SVG14536',
    },
    subTitle: {
      type: String,
      default: '公司动态',
    },
    mainTitle: {
      type: String,
      default: '标题示例标题示例标题示例标题示例标题示例标题示例',
    },

    timeStamp:{
      type: Number,
      default: 0,
    },
    newsID:{
      type: Number,
      default: 0,
    },
    newsListLength:{
      type: Number,
      default: 0,
    },
    newsData:null
  },
}
</script>

<style scoped>
.news-card-news-card {
  cursor: pointer;
  gap: 28px;
  max-width: 100vw;
  width: 370px;
  height: 460px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 1);
}

.news-card-cover {
  width: 370px;
  height: 206px;
  border-radius: 16px 16px 0 0;
  object-fit: cover;

}
.news-card-contentcontainer {
  width: 100%;
  padding: 30px;
  gap: 12px;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-direction: column;
}
.news-card-subtitle {
  width: 100%;
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.news-card-text {
  color: rgb(255, 255, 255);
  width: 146px;
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: justified;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 19.6px;
  font-stretch: normal;
  text-decoration: none;
}
.news-card-svg1 {
  width: 313px;
  height: 1px;
  object-fit: cover;

}
.news-card-text1 {
  color: rgb(255, 255, 255);
  width: 286px;
  height: auto;
  font-size: 18px;
  font-style: normal;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.2px;
  font-stretch: normal;
  text-decoration: none;
}
.news-card-text2 {
  color: rgb(255, 255, 255);
  width: 315px;
  height: auto;
  opacity: 0.80;
  font-size: 14px;
  font-style: Regular;
  text-align: justified;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 19.6px;
  font-stretch: normal;
  text-decoration: none;
}
.news-card-text3 {
  color: rgb(255, 255, 255);
  width: 146px;
  height: auto;
  opacity: 0.60;
  font-size: 14px;
  font-style: Regular;
  text-align: justified;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 19.6px;
  font-stretch: normal;
  text-decoration: none;
}
</style>
